.hdrTop{
    background:#393939;
    color:#fff;
}
.hdr--typeA .hdrBottom{
    background-color: #DCACA4;
    border:none;
    color:#fff;
}
#hdrLogo img{
    max-height: 80px;
}
.hdrMiddle .hdrInner{
    height: 100px;
}
.gNav > ul > li > a{
    color:#fff;
}
.hdr--typeA .hdrBottom a:hover{
    color:#fff;
}
.hdr--typeA .hdrBottom .hasChildren > a::after{
    border-color: #fff;
}
.hdr--typeA .hdrBottom .hasChildren > a:hover::after{
    border-color: #fff;
}
.gNav li.hasChildren > .megaMenu{
    background: $color_highlight_bg;
}
.megaMenu__button{
    background:#fff;
    border: none;
}
.gNav .megaMenu li a{
    font-weight: 500;
}
.gNav .megaMenu li a:hover{
    background-color: #fff;
}
@media (max-width: 991px){

    .hdrTop {
        .hdrInner:not(.hdrInner--one) .hdrInfo{
            text-align: center;
        }
    }
    .drawer__itemList{
        background: $color_highlight_bg;
    }
    .color_bg {
        background: $color_highlight_bg;
    }

    #ftr{
        .drawer__nav{
            > li{
                > a{
                    color: #fff;
                    font-size: 16px;
                    padding: 8px 0;
                    &::before, &::after{
                        background-color: #fff;
                    }
                }
                > ul {
                    padding: 10px 0;
                    > li > a{
                        border:none;
                        color: #fff;
                        padding: 5px 20px;
                        &::after{
                            content: none;
                        }
                    }
                }
            }
        }
        .fNav--typeA{
            padding-bottom: 30px;
        }
    }
}

#ftr{
    .p0{
        background: #393939;
        color:#fff;
        .fNav--typeA .fNav__list li a{
            color:#fff;
            font-weight: 400;
        }
    }
    .footer_bottom{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 30px;
        img{
            width: 89px;
        }
    }
    .snsNav {
        gap: 15px;
        li{
        font-size: 21px;
        margin:0;
        }
    }
    .copyright--typeA{
        background:#393939;
    }
}
@media (min-width: 992px){
    .fNav--typeA .row nav{
        order: 2;
    }
    .fNav--typeA .row div:last-child{
        order: 1;
    }
    .fNav--typeA .fNav__logo{ 
        text-align: left;
    }
    .fNav--typeA .snsNav{
        justify-content: flex-start;
    }
    .fNav--typeA .snsNav i{
        font-size: 22px;
    }
    .fNav--typeA .fNav__list li{
        font-size: 12px;
    }
}
.h2C{
    font-weight: 600;
    small{
        font-family: $base_font_family;
        font-weight: 700;
    }
    .border{
        position: relative;
        display: inline-block;
        &::before{
            content: '';
            background-color: $color_primary;
            height: 1px;
            width: 78px;
            position: absolute;
            top:50%;
            left: -108px;
            transform: translate(0, -50%);
        }
        &::after{
            content: '';
            background-color: $color_primary;
            height: 1px;
            width: 78px;
            position: absolute;
            top:50%;
            right: -108px;
            transform: translate(0, -50%);

        }
    }
}

.sec-tocustomer {
    text-align: center;
    .logo {
        text-align: center;
        img{
            width: 82px;
        }
    }
    .bnrList{
        padding: 25px;
        background: linear-gradient(273.58deg, #DCACA4 0%, #F6F2EE 99.6%);

        .bnr-box{
            .bnr-textbox{
                max-width: 900px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin: 0 auto;
                background-color: #fff;
                height: 250px;
                padding: 25px 40px;
                h3{
                    font-size: 20px;
                    font-family: $heading_font_family;
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    position: relative;
                    &::after{
                        content: '';
                        background-color: $color_primary;
                        height: 1px;
                        width: 150px;
                        position: absolute;
                        bottom:0;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                }
                p{
                    line-height: 28px;
                }
            }
        }
    }
}

.itemList--ranking.ranking--typeB .itemList__unit {
    .itemWrap:before{
        border:none;
        border-radius: 50%;
        color: #fff;
        background: #393939;
        font-weight: 700;
        font-size: 14px;
    }
    &:nth-child(1) .itemWrap:before, &:nth-child(2) .itemWrap:before, &:nth-child(3) .itemWrap:before{
        background-color: $color_secondary;
    }
}
.itemPrice{
    color: $color_secondary;
}
.tab--typeB {
    .tabSwitch {
        li{
            &:hover{
                color: $color_secondary;
            }
            &.active{
                color: $color_secondary;
                border-color: $color_secondary;
            }
        }
    }
}
.button-area{
    text-align: center;
    .button--typeA, a.button--typeA{
        background: #393939;
        &:hover{
            border-color: #393939;
            color:#393939;
            &::after{
                border-color: #393939;
            }
        }
    }
}
.sec-category{
    .tabContents{
        margin-bottom: 30px;
    }
    .iconButton{
        border: none;
        &:hover{
            background:#fff;
        }
        b{
            font-weight: 500;
        }
    }
}

.sec-banner{
    .row{
        justify-content: center;
        max-width: 790px;
        margin: 0 auto;
        p.banner-title{
            font-weight: 700;
            margin-bottom: 10px;
        }
    }
}
.faqWrap{
    margin-bottom: 40px;
}
.newsList{
    background: $color_highlight_bg;
    padding: 20px;
    ul{
        margin-left: 17%;
        li{
            border: none;
            time{
                color: #A9B9CC
            }
        }
    }
}
.sec-sns{
    background: url('../img/sec9_bg.jpg') center no-repeat;
    background-size: cover;
    .row{
        justify-content: center;
        gap: 30px;
        align-items: center;
        padding: 100px 0;
        .h2C{
            color:#fff;
            font-size: 24px;
            margin-bottom: 0;
            small{
                text-align: left;
                font-weight: 400;
                color:#fff;
            }
        }
        .sns-icon{
            background:#fff;
            border-radius: 50%;
            padding: 35px;
        }
    }
}
.shoppingGuide {
    .h2A{
        color:$color_primary;
        display: flex;
        justify-content: center;     
        align-items: center;
        gap: 20px;
        font-weight: 400;
        border-bottom: 2px solid $color_primary;
        small{
            font-family: $base_font_family;
            font-size: 16px;
        }
        i{
            margin: 0;
            font-size: 25px;
        }   
    }
    i.material-icons{
        color:$color_primary;
    }
}
@media (min-width: 992px){
}
@media (max-width: 991px){
    .sec-news{
        display: none;
    }
    .sec-tocustomer .bnrList{
        padding: 20px 10px;
        .bnr-box .bnr-textbox{
            height: auto;
            padding: 20px;
        }
    }
    .spRanking > .itemList__unit:nth-child(1), .spRanking > .itemList__unit:nth-child(2){
        flex: 0 0 50%;
    }
    .spRanking > .itemList__unit:nth-child(n + 9){
        display: none;
    }
    .itemList-sp--3 .itemList__unit{
        max-width:  100%;
    }
    .sec-recommend{
        .itemList__unit{
            &:nth-child(n + 9){
                display: none;
            }
        }
    }
    .row.cateList{
        gap: 15px 0;
    }
    .sec-news{
        .container{
            padding: 0;
        }
    }
    .newsList ul{
        margin: 0;
    }
    .sec-sns .row .h2C{
        width:100%;
        small{
            text-align: center;
            font-size: 14px;
        }
    }
    .sec-shopInfo{
        .container{
            padding: 0;
        }
        .row{
            flex-direction: column-reverse;
            h3{
                padding: 0 15px;
            }
            table.shopInfoTable{
                margin: 0 auto 10px;
                width: calc(100% - 30px);
            }
        }
    }
    .shoppingGuide .h2A{
        flex-direction: column;
        margin: 0 -15px;
        gap: 3px;
        padding-bottom: 10px;
    }
    .sec-checked{
        .itemList__unit{
            &:nth-child(n + 5){
                display: none;
            }
        }
    }
    .sec-favorite{
        .itemList__unit{
            &:nth-child(n + 5){
                display: none;
            }
        }
    }
}